.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;

	top: 0;
	padding: 0 1em;
	color: #888888;
	min-height: 6em;
	max-width: 100vw;
	background: #fff;
	box-shadow: 0px 4px 43px rgb(0 0 0 / 25%);
}
.navbar .title {
	flex-direction: column;
	align-items: center;
	display: flex;
	margin-left: 1em;
}

.navbar .leftSide .logo {
	max-width: 60px;
	max-height: 60px;
}
.navbar .title h1 {
	font-size: 20px;
	color: #000;
}

.navbar > div {
	display: flex;
	align-items: center;
}

.navbar a {
	text-decoration: none;
	align-items: center;
	color: black;
	margin-left: 1em;
}
.navbar .leftSide > div {
	margin-left: 1em;
	width: 300px;
}
.navbar .rightSide button {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 12em;
	margin: 0 1em;
	cursor: pointer;
	background: none;
	border: none;
}
.navbar .rightSide button p {
	font-weight: bold;
	font-size: 20px;
}

.navbar .rightSide button p + img {
	max-height: 40px;
}
.navbar .rightSide button img:last-child {
	max-height: 20px;
}
.navbar ~ .visible {
	max-width: fit-content;
	margin-left: auto;
	z-index: 2;
	position: absolute;
	right: 0;
}

section.visible {
	background-color: aliceblue;
	width: 100%;
	border-color: rgba(0, 0, 0, 0.2);
	border-style: solid;
	border-width: 0.1px;

	transition: opacity 1s;
	margin-bottom: 10px;
	border-radius: 8px;
}
@media (max-width: 850px) {
	.navbar {
		padding: 1em;
		flex-wrap: wrap;
		justify-content: center;
	}
	.navbar .rightSide {
		margin-top: 1em;
	}
}
@media (max-width: 500px) {
	.navbar a {
		margin: 0;
	}
	.navbar .leftSide {
		justify-content: center;
	}
	.navbar .leftSide .search-input {
		display: none;
	}
	.navbar .rightSide {
		justify-content: center;
		flex-wrap: wrap;
	}
}
