.pop-up {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 700px;
	padding: 15px;
	background: white;
	border-radius: 8px;
	z-index: 2;
	box-shadow:
		0 12px 28px 0 rgb(0 0 0 / 20%),
		0 2px 4px 0 rgb(0 0 0 / 10%),
		inset 0 0 0 1px rgb(255 255 255 / 50%);
}

.textarea-top {
	display: flex;
	background-color: #f0f0f0;
	border-radius: 4px 4px 0px 0px;
}
.char-count {
	align-self: center;
	font-size: 14px;
	color: #999;
	margin-left: auto;
	padding: 0 10px;
}

input#devocional:checked + img,
input#devocionaly:checked + img {
	background-color: pink;
}
input#exegese:checked + img,
input#exegesey:checked + img {
	background-color: yellow;
}
input#inspirado:checked + img,
input#inspiradoy:checked + img {
	background-color: cyan;
}
input#pessoal:checked + img,
input#pessoaly:checked + img {
	background-color: chartreuse;
}
