$textHighlightColor: #cbe4f9;

ul.verse-list li {
	margin-top: 10px;
	line-height: 25px;
	font-size: 18px;
	display: flex;
}
ul.verse-list li p {
	cursor: pointer;
}

ul.verse-list li sup {
	margin-right: 0.5em;
}
ul.verse-list p.verse-text[data-active="true"] {
	background-color: $textHighlightColor;
}
