.search-input div {
	padding: 1em;
	display: flex;
	border-radius: 20px;
	border: 1px solid #ddd;
}
.search-input div.on-search {
	border-radius: 20px 20px 0 0;
}
.search-input input {
	width: 100%;
	border: none;
	margin-left: 1em;
	font-size: initial;
}
.search-input .close-icon {
	margin-left: auto;
	cursor: pointer;
}
.search-input div.on-search + ul {
	display: block;
}
.search-input ul {
	display: none;
	position: absolute;
	flex-direction: column;
	z-index: 2;

	background: #fff;
	font-size: initial;
	overflow-y: scroll;
	max-height: 80vh;

	padding: 1em;
	border: 1px solid #ddd;
	border-radius: 0 0 20px 20px;
	box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}
.search-input ul li {
	width: 100%;
	height: 40px;
	cursor: pointer;
	overflow-x: hidden;
	overflow-y: hidden;
	margin-bottom: 5px;
	white-space: inherit;
	text-overflow: ellipsis;
}
.search-input ul li:hover {
	color: #000;
}
.search-input > * {
	width: 35rem;
}
@media screen and (max-width: 1160px) {
	.search-input > * {
		width: inherit;
	}
}
