.answersComponent {
	display: flex;
	align-items: center;
	justify-content: center;
}
.answersComponent > div.pop-up {
	width: min(700px, 100vw);
	max-width: 100vw;
}
.answersComponent .top h1 {
	margin-left: 1em;
}
.answersComponent h2 {
	margin: 1em 1.3em;
}
.answersComponent .reply-area > div {
	border: 1px solid #dcdce6;
	width: 100%;
}
.answersComponent .answer-list h3 {
	color: #111;
}
.answerBlur {
	position: absolute;
	width: 100%;
	height: 100%;
}
