@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	width: 100vw;
}

body,
input,
button {
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	font-size: 12px;
	max-width: 100%;
}

.container {
	display: flex;
	justify-content: center;
	margin: 20px auto;
	width: 90vw;
}

.text-area {
	margin-top: 8px;
	width: 100%;
	border-radius: 0 0 4px 4px;
}

.visible {
	animation: fade-in 1s ease-out;
	opacity: 1;
}

.invisible {
	opacity: 0;
	position: fixed;
	z-index: -1;
	pointer-events: none;
}

textarea {
	border: 1px solid #dcdce6;
	font-size: 15px;
	padding: 20px;

	max-height: 600px;
	min-height: 100px;
	width: 100%;
	resize: vertical;
	border: none;
}
.text-area:focus {
	border-color: #666668;
}

.top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1em;
	border-bottom: solid 0.1px #ddd;
}

.top h2 {
	margin-left: 5%;
	width: 100%;
	text-align: center;
}

.top button {
	margin-right: 5%;
	width: 20px;
	height: 20px;
	border: 0;
	background-color: white;
	cursor: pointer;
}

img.tag {
	height: 30px;
	padding: 4px;
	margin-top: 4px;
	border-radius: 4px;
}

input[type="checkbox"] ~ div {
	background-color: aliceblue;
	padding: 10px 10px;
	opacity: 0;
	z-index: -1;
	position: fixed;
}

input[type="checkbox"]:checked ~ div {
	opacity: 1;
	z-index: 0;
	position: relative;
}
.load-btn {
	height: 4em;
	width: -webkit-fill-available;
	font-weight: bolder;
	color: white;
	border: none;
	cursor: pointer;
	background-color: #0dffd4;
}
.load-btn:hover {
	background-color: #7fffd4;
}

@keyframes fade-in {
	0% {
		right: 20%;
		opacity: 0;
	}
	30% {
		right: 0%;
		opacity: 0.1;
	}
	100% {
		opacity: 1;
	}
}
