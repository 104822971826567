.title-comments .tag {
	margin-top: 0;
}

.title-comments li {
	margin-top: 10px;
}
.title-comments > div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.title-comments li > p {
	font-size: 14px;
	line-height: 18px;
	text-align: justify;
	white-space: break-spaces;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif;
}
.title-comments li p {
	padding-left: 5px;
}

.title-comments li h3 {
	display: flex;
	align-items: center;
}

label[for="toggle"] {
	font-size: x-large;
	font-weight: bold;
}

label {
	cursor: pointer;
}

input[type="checkbox"] {
	display: none;
}

label img:hover {
	background-color: #dadada;
}
