/* principal */
div.chapter-container {
	display: flex;
	flex-direction: column;
}

div.main {
	max-width: 750px;
	background-color: white;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 80px;
	box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
}

button.entry:disabled {
	background: #eee;
	color: #bbb;
	cursor: not-allowed;
}

button.entry {
	width: 100%;
	height: 40px;
	background: #20e0c0;
	border: 0;
	border-radius: 4px;
	color: #fff;
	font-weight: 700;
	margin-top: 8px;
	font-size: 16px;
	cursor: pointer;
}
button.entry:disabled,
button.entry:hover:disabled {
	background: #e4e6eb;
	color: #bcc0c4;
	cursor: auto;
}
button.entry:hover {
	background: #1ad6b7;
}

/* verse commentaries */
aside {
	position: relative;
	margin-left: 20px;
	width: 500px;
}

.chapter-container div.amount {
	min-width: 25px;
	height: 25px;
	border-radius: 4px;
	margin-left: auto;
	background-color: lightgray;

	align-self: center;
	text-align: center;
	font-size: large;
	font-weight: normal;
}
