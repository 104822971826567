.sideComments {
	display: flex;
	position: fixed;
	align-items: center;
	flex-direction: column;

	background-color: white;
	border-radius: 0 8px 8px;
	margin: 0 20px;
	padding: 20px;

	width: 350px;
	max-height: 77%;
	box-shadow: 0px 0px 15px rgb(0 0 0 / 20%);
}
.sideComments .top h2 {
	align-self: center;
}
.sideComments .buttonContainer {
	flex-direction: column;
	align-items: center;
	display: flex;
	width: 100%;
}

ul.commentaries {
	height: 88%;
	overflow: auto;
	width: 100%;
}

ul.commentaries li h3 {
	display: flex;
}
ul.commentaries li label {
	display: flex;
}
ul.commentaries li h3 img {
	height: 1rem;
	margin: 0 4px;
}

ul.commentaries > li {
	margin-top: 10px;
}

p.label-title {
	padding-left: 5px;
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 30px;
}

.user-comment {
	display: flex;
	flex-direction: column;
}

.user-comment p {
	font-size: 14px;
	line-height: 18px;
	text-align: justify;
	white-space: break-spaces;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif;
}

@media (max-width: 1000px) {
	.sideComments {
		position: relative;
		right: 1px;
		min-width: 300px;
		margin: 0 10px;
		height: auto;
		max-height: 25%;
	}
}
@media (max-width: 800px) {
	.sideComments {
		position: fixed;
		width: inherit;
		max-height: 70%;
	}
}

span.comment-buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

span.comment-buttons button {
	margin-left: 10px;
	border: none;
	cursor: pointer;
	background: none;
}

span.comment-buttons button:active {
	transform: scale(1.5);
}

span.comment-buttons img {
	width: 20px;
}

h3 sub {
	display: flex;
	flex: 1;
	align-items: flex-end;
	justify-content: flex-end;
}
