.login-container {
	background-color: white;
	border-radius: 8px;
	box-shadow: -1px 1px 20px -5px grey;

	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.login-container form,
.login-container section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1.5rem;
	width: 100%;
}

.login-container input {
	text-indent: 20px;
	border: #bbbbbb groove 1px;
	background-color: white;
	border-radius: 4px;
	width: 100%;
	height: 60px;
	margin-bottom: 15px;
	font-size: larger;
}

.login-container input[type="submit"] {
	border-radius: 6px;
	border: none;

	text-indent: 0;
	text-align: center;

	background-color: #888;
	font-weight: bolder;
	color: white;
	height: 50px;

	cursor: pointer;
}

.login-container > section > button,
.login-container > form > button {
	margin-top: 10px;
	border-radius: 6px;
	border: none;
	width: 70%;
	background-color: #1e7;
	font-weight: bolder;
	color: white;
	height: 50px;
	cursor: pointer;
	font-size: large;
}

.login-container > form input[type="submit"] {
	background-color: #1e7;
}

.login-container > form button {
	background-color: #888;
}

hr {
	color: black;
	width: inherit;
}

button:hover,
input:hover {
	opacity: 0.9;
}
